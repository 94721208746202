
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93w3p9L5eD1qMeta } from "/workspace/pages/blog/[id].vue?macro=true";
import { default as indexRgrKfU8NaHMeta } from "/workspace/pages/blog/index.vue?macro=true";
import { default as blogykM290Kgh8Meta } from "/workspace/pages/blog.vue?macro=true";
import { default as contact8zxLAXYQX4Meta } from "/workspace/pages/contact.vue?macro=true";
import { default as hallOfFameQ70LZcD2ZQMeta } from "/workspace/pages/hallOfFame.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91id_935jDikbHulgMeta } from "/workspace/pages/levels/[id].vue?macro=true";
import { default as _91video_93bKJS5rReNmMeta } from "/workspace/pages/levels/[video].vue?macro=true";
import { default as indexlmy6pe4wSEMeta } from "/workspace/pages/levels/index.vue?macro=true";
import { default as levelsuEYSHfNNL7Meta } from "/workspace/pages/levels.vue?macro=true";
import { default as loginGeKhs9qf7uMeta } from "/workspace/pages/login.vue?macro=true";
import { default as registrationzJtdLNH9pfMeta } from "/workspace/pages/registration.vue?macro=true";
export default [
  {
    name: blogykM290Kgh8Meta?.name,
    path: "/blog",
    component: () => import("/workspace/pages/blog.vue"),
    children: [
  {
    name: "blog-id___cs",
    path: ":id()",
    component: () => import("/workspace/pages/blog/[id].vue")
  },
  {
    name: "blog___cs",
    path: "/blog",
    component: () => import("/workspace/pages/blog/index.vue")
  }
]
  },
  {
    name: "contact___cs",
    path: "/napiste-andelovi",
    component: () => import("/workspace/pages/contact.vue")
  },
  {
    name: "hallOfFame___cs",
    path: "/sin-slavy",
    component: () => import("/workspace/pages/hallOfFame.vue")
  },
  {
    name: "index___cs",
    path: "/",
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: levelsuEYSHfNNL7Meta?.name,
    path: "/levels",
    component: () => import("/workspace/pages/levels.vue"),
    children: [
  {
    name: "levels-id___cs",
    path: "/urovne/:id()",
    component: () => import("/workspace/pages/levels/[id].vue")
  },
  {
    name: "levels-video___cs",
    path: "/urovne/:id()/:video()",
    component: () => import("/workspace/pages/levels/[video].vue")
  },
  {
    name: "levels___cs",
    path: "/urovne",
    component: () => import("/workspace/pages/levels/index.vue")
  }
]
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    meta: loginGeKhs9qf7uMeta || {},
    component: () => import("/workspace/pages/login.vue")
  },
  {
    name: "registration___cs",
    path: "/registrace",
    meta: registrationzJtdLNH9pfMeta || {},
    component: () => import("/workspace/pages/registration.vue")
  }
]