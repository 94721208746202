export function useDate() {
  function isToday(date: Date) {
    const today = new Date() // Get today's date
    return (
      date.getDate() === today.getDate()
      && date.getMonth() === today.getMonth()
      && date.getFullYear() === today.getFullYear()
    )
  }

  return {
    isToday,
  }
}
