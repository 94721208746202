import { consola } from 'consola'

export default defineNuxtPlugin({
  dependsOn: ['network'],
  async setup(nuxtApp) {
    const authStore = useAuthenticationStore(nuxtApp.$pinia)
    authStore.setBugsnagUser()

    if (authStore.isAuthenticated) {
      try {
        await authStore.getMe()
      } catch (e) {
        consola.error(e)
      }
    }
  },
})
