interface AppState {
  cmsToken: string | null
  user: User | null
}

interface Progress {
  value: number
  date: string
  isActive: boolean
  id: number
  createdAt: string
  updatedAt: string
  user: number
}

export interface User extends UserCreate {
  id: number
  progress: Progress[]
}

export interface UserCreate extends UserUpdate {
  email: string
  password: string
  confirmPassword: string
  firstname: string
  lastname: string
  role: string
  branch: string
  points: number
  glory: number
  height: number
  weight: number
  dateOfBirth: string
  trialExpiration?: string
  healthPlanPath?: string
  completedVideos?: number[]
}

export type UserPopulations = 'currentLevel' | 'pulses' | 'weights' | 'transactions' | 'progress' | 'videoComments'

export interface UserUpdate {
  dailyTarget: number | string
}

const { isToday } = useDate()

export const useAuthenticationStore = defineStore('authentication', {
  /**
   * Represents the state of the application.
   */
  state: (): AppState => ({
    cmsToken: null,
    user: null,
  }),
  getters: {
    isAuthenticated(state: AppState): boolean {
      return state.cmsToken !== null && state.user !== null
    },
    fullName(state: AppState): string {
      return [state.user?.firstname, state.user?.lastname].join(' ')
    },
    completedVideos(state: AppState): number[] {
      return state.user?.completedVideos || []
    },
    dailyProgress(state: AppState): number {
      const progresses = state.user?.progress || []
      let progress = 0
      const todayProgress = progresses.find(progress => isToday(new Date(progress.date)))

      progress = todayProgress?.value || 0

      return progress
    },
    dailyTarget(state: AppState): number {
      return +(state.user?.dailyTarget || 0)
    },
  },
  actions: {
    setUser(user: User) {
      this.user = user
    },
    setBugsnagUser() {
      if (this.isAuthenticated) {
        useBugsnag().setUser(this.user?.id, this.user?.email, [this.user?.firstname, this.user?.lastname].join(' '))
      } else {
        useBugsnag().setUser(undefined)
      }
    },
    async createToken({ email, password }: { email: string, password: string }) {
      const result = await useNuxtApp().$cmsFetch('/user/token/create', {
        method: 'POST',
        body: {
          email,
          password,
        },
      })
      this.cmsToken = result.token
      this.user = result.user
      this.setBugsnagUser()
    },
    verifyToken({ token }: { token: string }) {
      return useNuxtApp().$cmsFetch('/user/token/verify', {
        method: 'POST',
        body: {
          token,
        },
      })
    },
    logout() {
      this.cmsToken = null
      this.user = null
    },

    async getMe() {
      const result = await useNuxtApp().$cmsFetch('/user/me', {
        method: 'GET',
        query: {
          populate: ['currentLevel', 'pulses', 'weights', 'progress'].join(','),
        },
      })
      this.user = result as User
    },
    async createUser(config: UserCreate) {
      const result = await useNuxtApp().$cmsFetch('/user', {
        method: 'POST',
        body: {
          ...config,
          trial: true,
        },
      })
      this.cmsToken = result?.token
      this.user = result?.user
      this.setBugsnagUser()
    },
    updateUser(config: UserUpdate) {
      return useNuxtApp().$cmsFetch('/user/token/create', {
        method: 'PUT',
        body: {
          ...config,
        },
      })
    },
  },
  persist: true,
})
